import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Card } from 'Common'
import starIcon from 'Static/icons/star.svg'
import forkIcon from 'Static/icons/fork.svg'
import { Wrapper, Grid, Item, Content, Stats } from './styles'

export const Projects = () => {
  const {
    github: {
      repositoryOwner: {
        repositories: { edges },
      },
    },
  } = useStaticQuery(graphql`
    {
      github {
        repositoryOwner(login: "gbs4ever") {
          repositories(
            first: 7

            orderBy: { field: STARGAZERS, direction: DESC }
          ) {
            edges {
              node {
                id
                isPrivate
                name
                url
                description
                stargazers {
                  totalCount
                }
                forkCount
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {edges.map(({ node }) => (
          //  node.isPrivate == true ? node.url = "#" : "test"

          <Item
            key={node.id}
            as="a"
            href={node.isPrivate == true ? '#' : node.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <Content>
                <h4>{node.name}</h4>
                <p>{node.isPrivate == true ? 'This repor is private' : ''}</p>
                <p>{node.description}</p>
              </Content>
              <Stats>
                <div>
                  <img src={starIcon} alt="stars" />
                  <span>{node.stargazers.totalCount}</span>
                </div>
                <div>
                  <img src={forkIcon} alt="forks" />
                  <span>{node.forkCount}</span>
                </div>
              </Stats>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  )
}
